// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Input
\* ------------------------------------------------------------------------ */

.input {
    &,
    & > .input_inner,
    &.-checkbox + .text.-checkbox .svg-icon,
    &.-radiobutton + .text.-radiobutton .svg-icon {
        background: $background;
        border: remify(1) solid darken($background_alt, 10);
        color: $foreground;
        display: block;
        font-family: $body-font;
        font-size: remify(16, 16);
        font-weight: 400;
        line-height: remify(24, 16);
        margin: remify(0 0 24, 16);
        outline: 0;
        padding: remify(8 12, 16);
        transition: border-color 0.15s, color 0.15s;
        width: 100%;
    }

    &:focus,
    &:hover,
    & > .input_inner:focus,
    & > .input_inner:hover,
    &.-checkbox:focus + .text.-checkbox .svg-icon,
    &.-checkbox + .text.-checkbox:hover .svg-icon,
    &.-radiobutton:focus + .text.-radiobutton .svg-icon,
    &.-radiobutton + .text.-radiobutton:hover .svg-icon {
        border-color: $accent;
    }
}

// select variant

.input.-select {
    & {
        background: none;
        border: 0;
        padding: 0;
        position: relative;
    }

    .input_inner {
        margin-bottom: 0;
        padding: remify(8 12 13, 16);
        position: relative;
        z-index: 1;
        // remove Firefox default styles & arrow
        text-indent: 0.01px;
        text-overflow: "";
        -moz-appearance: none;
        // remove Chrome/Safari default styles & arrow
        -webkit-appearance: none;
        // remove IE default styles & arrow
        &::-ms-expand {display: none;}
    }

    &.-arrow .input_inner {
        padding-right: remify(48, 16);
    }

    .input_inner:focus,
    .input_inner:hover {
        border-color: $accent;
    }

    .svg-icon {
        color: inherit;
        display: block;
        fill: currentColor;
        font-size: remify(16, 16);
        height: remify(16, 16);
        pointer-events: none;
        position: absolute;
        right: remify(16, 16);
        transform: translateY(-50%);
        transition: color 0.15s;
        top: 50%;
        width: remify(16, 16);
        z-index: 2;
    }

    .input_inner:focus + .svg-icon,
    .input_inner:hover + .svg-icon {
        color: $accent;
    }
}

// checkbox & radiobutton variants

.input.-checkbox,
.input.-radiobutton {
    & {
        left: -999em;
        position: absolute;
    }

    + .text.-label {
        padding-left: remify(32, 16);
        position: relative;
    }

    + .text.-label:before {
        border: 1px solid currentColor;
        content: "\0020";
        font-size: remify(8, 16);
        height: remify(16, 8);
        left: 0;
        line-height: remify(15, 8);
        margin: 0;
        padding: 0;
        position: absolute;
        text-align: center;
        transform: translateY(-50%);
        top: 50%;
        width: remify(16, 8);
    }

    &.-radiobutton + .text.-label:before {
        border-radius: 50%;
    }

    &.-checkbox:checked + .text.-label:before {
        @include icon("check");
    }

    &.-radio:checked + .text.-label:before {
        @include icon("circle");
    }
}
