// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-block {
    &.-fullbleed {
        padding: remify(30, 16);
    }
}

.content_inner {
    .content-block.-fullbleed > & {
        background: $background;
        border: remify(10, 16) solid $tertiary;
        border-radius: remify(20, 16);
        box-shadow: remify(0 0 8 1, 16) transparentize($dark, 0.75);
        padding: remify(25);
    }
}

.content_post {
    & {
        padding: remify(25);
    }
}

.content_sidebar {
    & {
        padding: remify(25 0);
    }
}

.content_sidebar {
    .content_button {
        border-radius: remify(10, 21);
    }
}

// clean variant

.content-block.-clean {
    & {
        padding: remify(30 0);
    }

    &.-fullbleed > .content_inner {
        background: none;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
    }

    .content_inner > .content_row:first-of-type:not(:last-of-type) {
        margin-bottom: remify(40, 16);
    }

    .content_inner > .content_row:last-of-type:not(:first-of-type),
    .content_inner > .content_row:first-of-type > .col:last-of-type:not(:only-of-type) {
        background: none;
        padding: 0;
    }

    .content_inner > .content_row > .col:last-of-type > .content_widget.-spaced {
        margin-right: 0;
    }
}

// split variant

.content-block.-split {
    & {
        background: linear-gradient(to right, $accent 50%, $primary_alt 50%);
    }

    &.-fullbleed {
        padding: 0;
    }

    &:before {
        background: url("../media/background-paper.png") center remify(-6, 16) no-repeat;
        content: "\0020";
        display: block;
        height: remify(6, 16);
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 999;
    }

    &.-fullbleed > .content_inner {
        background: none;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
    }

    .content_row > .col {
        padding: remify(30 40);
    }

    .content_row > .col:nth-of-type(odd) {
        background: url("../media/background-woman.jpg") right top / contain no-repeat $accent;
    }

    .content_row > .col:nth-of-type(even) {
        background: url("../media/background-field.jpg") left top / contain no-repeat $primary_alt;
    }

    .content_button.-dashed {
        margin: remify(110 auto 90, 46);
        max-width: 82.5%;
    }
}
