// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    & {
        @include layer("footer");
        padding: remify(25);
        text-align: center;
    }

    &.-fullbleed {
        background: url("../media/background-dots.png") left remify(-150, 16) repeat-x $primary;
    }

    &.-fullbleed:before {
        background: url("../media/background-paper.png") center center no-repeat;
        content: "\0020";
        display: block;
        height: remify(12, 16);
        left: 0;
        position: absolute;
        right: 0;
        top: remify(-6, 16);
    }
}

.footer_inner {
    & {
        background: url("../media/background-dots.png") left remify(-150, 16) repeat-x $primary;
        margin: 0 auto;
        max-width: remify($site-width);
    }

    &:before {
        background: url("../media/background-paper.png") center center no-repeat;
        content: "\0020";
        display: block;
        height: remify(12, 16);
        left: 0;
        position: absolute;
        right: 0;
        top: remify(-6, 16);
    }

    .footer-block.-fullbleed > & {
        background: none;
    }

    .footer-block.-fullbleed > &:before {
        display: none;
    }
}

.footer_link {
    & {
        color: $light;
    }

    &:focus,
    &:hover {
        color: darken($light, 10);
    }
}

.footer_title {
    & {
        color: $tertiary;
        font-size: remify(33.6, 16);
        margin-bottom: remify(6, 33.6);
    }
}

.footer_text {
    & {
        color: $light;
        font-size: remify(11.2, 16);
    }
}

.footer_logo {
    & {
        margin: remify(0 auto 12, 16);
        max-width: remify(175, 16);
    }
}

.footer_menu-list_container {
    .menu-list.-social {
        font-size: remify(32, 16);
        float: right;
        margin: remify(0 0 24, 32);
        right: 50%;
    }

    .menu-list.-social .menu-list_item {
        left: 50%;
    }
}
