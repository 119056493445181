// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header_logo {
    & {
        padding: remify(10 45);
        max-width: remify(330);
    }

    .logo_image {
        max-width: none;
    }
}

.header_search-form_container {
    .search-form_input {
        padding: remify(32 50 23 30, 17);
    }
}
