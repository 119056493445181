// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-block {
    & {
        @include layer("content");
    }

    .is-fluidboxactive & {
        position: static;
    }

    &.-fullbleed {
        background: url("../media/background-paper.png") center remify(274, 16) no-repeat, url("../media/background-farm.jpg") center top / remify(auto 280, 16) no-repeat, url("../media/background-dots.png") remify(0 280, 16) repeat-x, linear-gradient(to bottom, $tertiary remify(280, 16), lighten(saturate(adjust-hue($tertiary, -0.6250), 0.3922), 1.5686) 40%, lighten(desaturate($tertiary, 2.9412), 10.9804) 100%);
    }
}

.content_inner {
    & {
        background: url("../media/background-paper.png") center remify(274, 16) no-repeat, url("../media/background-farm.jpg") center top / remify(auto 280, 16) no-repeat, url("../media/background-dots.png") remify(0 280, 16) repeat-x, linear-gradient(to bottom, $tertiary remify(280, 16), lighten(saturate(adjust-hue($tertiary, -0.6250), 0.3922), 1.5686) 40%, lighten(desaturate($tertiary, 2.9412), 10.9804) 100%);
        margin: 0 auto;
        max-width: remify($site-width);
    }

    .content-block.-fullbleed > & {
        background: $background;
    }
}

.content_post {
    & {
        padding: remify(25);
    }
}

.content_sidebar {
    .content_button {
        border-radius: 0;
    }
}

.content_row {
    &.-padded {
        width: calc(100% + #{remify(35, 16)});
    }

    &.-padded > .col {
        padding-right: remify(35, 16);
    }
}

// clean variant

.content-block.-clean {
    .content_inner > .content_row:last-of-type:not(:first-of-type) {
        background: linear-gradient(to bottom, $tertiary 0%, lighten(saturate(adjust-hue($tertiary, -0.6250), 0.3922), 1.5686) 40%, lighten(desaturate($tertiary, 2.9412), 10.9804) 100%);
    }

    .content_inner > .content_row:first-of-type > .col:last-of-type:not(:only-of-type) {
        background: url("../media/background-paper.png") center bottom no-repeat, url("../media/background-farm_tall.jpg") center bottom remify(6, 16) / cover no-repeat, $tertiary;
        padding: remify(1);
    }
}

// split variant

.content-block.-split {
    & {
        padding: 0;
    }

    .content_row > .col {
        padding: remify(25);
    }

    .content_row > .col:nth-of-type(odd) {
        background: url("../media/background-paper.png") center remify(-6, 16) no-repeat, url("../media/background-woman.jpg") right top / cover no-repeat $accent;
    }

    .content_row > .col:nth-of-type(even) {
        background: url("../media/background-field.jpg") left top / cover no-repeat $primary;
    }

    .content_button.-dashed {
        margin: remify(60 0 40, 27.5);
    }
}
