// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

.widget {
    & {
        background: $tertiary;
        border: remify(5) solid $tertiary;
    }

    &:not(:last-child) {
        margin-bottom: remify(17.5, 16);
    }
}

.widget_header {
    & {
        background: darken(desaturate(adjust-hue($tertiary, -1.3393), 0.2404), 10.3922);
        padding: remify(11 25 10, 16);
        position: relative;
    }
}

.widget_badge {
    & {
        left: remify(-17.5, 27.21);
        position: absolute;
        top: remify(-17.5, 27.21);
    }
}

.widget_title {
    & {
        color: $primary;
        font-family: $body-font;
        font-size: remify(11.5, 16);
        text-align: center;
    }

    .widget_badge + & {
        padding-left: remify(55, 11.5);
        text-align: right;
    }
}

.widget_content {
    & {
        overflow: hidden;
        padding: remify(10, 16);
    }
}

// spaced variant

.widget.-spaced {
    & {
        border-radius: remify(15, 16);
        box-shadow: remify(0 0 8 1, 16) transparentize($dark, 0.75);
        margin: remify(20, 16);
    }

    .widget_header {
        border-radius: remify(10 10 0 0, 16);
    }
}

// bordered variant

.widget.-bordered {
    & {
        background: none;
        border-image: url("../media/border-dashed_primary.png") 25 fill repeat;
        border-style: solid;
        border-width: remify(25, 16);
        margin: remify(10, 16);
        position: relative;
    }

    &:before {
        background: $background;
        border-radius: remify(7.5, 16);
        bottom: remify(-12, 16);
        content: "\0020";
        display: block;
        left: remify(-12, 16);
        position: absolute;
        right: remify(-12, 16);
        top: remify(-12, 16);
        z-index: 0;
    }

    .widget_content {
        position: relative;
        padding: remify(12 14, 16);
        z-index: 1;
    }
}

// clean variant

.widget.-clean {
    & {
        background: none;
        border: 0;
    }

    .widget_content {
        padding: remify(20 25, 16);
    }
}

// dark variant

.widget.-dark {
    & {
        background: $primary_alt;
    }

    .widget_header {
        background: $primary;
    }

    .widget_title {
        color: $light;
    }

    .widget_menu-list_container .menu-list.-callout.-vertical > .menu-list_item:not(:last-child) {
        background-image: url("../media/border-dashed_horizontal_tertiary.png");
    }

    .widget_menu-list_container .menu-list.-callout .menu-list_link {
        color: $light;
    }

    .widget_menu-list_container .menu-list.-callout > .menu-list_item:last-child > .menu-list_link {
        color: $secondary;
    }

    .widget_menu-list_container .menu-list.-callout .menu-list_link:focus,
    .widget_menu-list_container .menu-list.-callout .menu-list_link:hover {
        color: darken($light, 10);
    }

    .widget_menu-list_container .menu-list.-callout > .menu-list_item:last-child > .menu-list_link:focus,
    .widget_menu-list_container .menu-list.-callout > .menu-list_item:last-child > .menu-list_link:hover {
        color: $secondary_alt;
    }
}
