// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Navigation
\* ------------------------------------------------------------------------ */

.navigation_menu-list_container {
    .menu-list.-navigation {
        font-size: remify(16, 16);
        font-size: 1.5625vw; // 20px @ 1280px wide
    }
}
