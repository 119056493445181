// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Navigation
\* ------------------------------------------------------------------------ */

.navigation_menu-list_container {
    .menu-list.-navigation {
        font-size: remify(20, 16);
    }
}
