// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

.widget {
    & {
        border-radius: remify(20, 16);
        border-width: remify(10);
    }

    &:not(:last-child) {
        margin-bottom: remify(22, 16);
    }
}

.widget_header {
    & {
        padding: remify(16 25 12, 16);
    }
}

.widget_badge {
    & {
        left: remify(-30, 40);
        top: remify(-30, 40);
    }
}

.widget_title {
    & {
        font-size: remify(17, 16);
        margin-bottom: 0;
    }

    .widget_badge + & {
        padding-left: remify(55, 17.5);
    }
}

.widget_content {
    & {
        padding: remify(10, 16);
    }
}

// big variant

.widget.-big {
    .widget_content {
        padding: remify(0 35);
    }

    .widget_menu-list_container .menu-list.-callout {
        font-size: remify(30, 16);
    }

    .widget_menu-list_container .menu-list.-callout .menu-list_link {
        padding: remify(12 14 16, 30);
    }
}

// spaced variant

.widget.-spaced {
    & {
        border-radius: remify(20, 16);
        box-shadow: remify(0 0 8 1, 16z) transparentize($dark, 0.75);
        margin: remify(32, 16);
    }

    .widget_header {
        border-radius: remify(10 10 0 0, 16);
    }
}
