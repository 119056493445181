// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * SVG Icon
\* ------------------------------------------------------------------------ */

.svg-icon {
    & {
        color: inherit;
        display: inline-block;
        fill: currentColor;
        font-size: remify(16, 16);
        height: remify(16, 16);
        width: remify(16, 16);
    }
}
