// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Search Form
\* ------------------------------------------------------------------------ */

.search-form {
    & {
        position: relative;
    }

    @include placeholder {
        color: $light;
        opacity: 1;
    }
}

.search-form_input {
    & {
        background: $primary;
        border: remify(1) solid $primary;
        color: $light;
        font-family: $body-font;
        font-size: remify(17, 16);
        font-weight: 700;
        margin: 0;
        outline: none;
        padding: remify(15 50 15 15, 17);
        transition: border-color 0.15s;
        width: 100%;
    }

    &:focus,
    &:hover {
        border-color: $primary_alt;
    }
}

.search-form_button {
    & {
        background: none;
        border: 0;
        bottom: remify(1);
        color: $light;
        cursor: pointer;
        font-size: remify(26, 16);
        margin: 0;
        padding: remify(5 12 0, 26);
        position: absolute;
        right: remify(1);
        top: remify(1);
        transition: color 0.15s;
    }

    &:focus,
    &:hover {
        color: darken($light, 10);
    }
}

// dumb Drupal stuff


.search-form [for="edit-keys"] {
    & {
        left: -999em;
        position: absolute;
        top: -999em;
    }
}

.search-form .container-inline {
    & {
        margin: remify(0 0 20, 16);
        position: relative;
    }
}

.search-help-link {
    & {
        @extend .text;
    }
}
