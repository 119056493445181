// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Gridly (adapted from https://github.com/IonicaBizau/gridly)
\* ------------------------------------------------------------------------ */

.row {
    & {
        display: flex;
    }
}

.col {
    & {
        flex: 1 1 100%;
        min-width: 0; // fixes weird overflow issues
    }

    &.-tenth {
        flex: 1 0 10%;
    }

    &.-fifth {
        flex: 1 0 20%;
    }

    &.-quarter {
        flex: 1 0 25%;
    }

    &.-third {
        flex: 1 0 33.3333334%;
    }

    &.-half {
        flex: 1 0 50%;
    }

    &.-none {
        flex: 0 0 auto;
    }

    &.-spacer {
        flex: 1 1 0%;
    }
}
