// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Normalize
\* ------------------------------------------------------------------------ */

// Vendor

@import "../../vendor/_normalize";

// Custom

/**
 * Change the box-sizing of all elements
 */

*,
*:before,
*:after {
    box-sizing: border-box;
}

/**
 * Remove the border-radius on inputs in iOS
 */

input,
textarea,
select,
[type="search"] {
    border-radius: 0;
    -webkit-appearance: none;
}
