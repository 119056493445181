// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Text
\* ------------------------------------------------------------------------ */

.text {
    & {
        color: $foreground;
        display: block;
        font-family: $body-font;
        font-size: remify(13.5, 16);
        font-weight: 400;
        line-height: remify(17.6, 13.5);
        margin: remify(0 0 17.6, 13.5);
    }
}

/* label variant */

.text.-label {
    & {
        cursor: pointer;
    }
}

// desktop variant

@media screen and (min-width: $s-break) {
    .text {
        font-size: remify(17, 16);
    }
}
