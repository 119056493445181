// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Badge
\* ------------------------------------------------------------------------ */

.badge {
    & {
        border-width: remify(5, 40);
        font-size: remify(40, 16);
    }
}
