// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

// Vendor

@import "../../vendor/_swiper";

// Custom

.swiper-container {
    & {
        background: $background;
        border-top: remify(3, 16) solid $tertiary;
    }
}

.swiper-slide {
    & {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: auto;
        margin: 0;
    }
}

.swiper-picture,
.swiper-picture {
    & {
        flex: none;
    }
}

.swiper-image {
    & {
        display: block;
        height: auto !important;
        width: 100%;
    }
}

.swiper-caption {
    & {
        background: $background;
        border-bottom: remify(5, 16) solid $tertiary;
        border-top: remify(5, 16) solid $tertiary;
        box-shadow: remify(0 0 8 1, 16) transparentize($dark, 0.75);
        flex: 1 1 auto;
        padding: remify(12.5 38.5, 16);
    }
}

.swiper-user-content {
    a {
        color: $secondary;
        font-weight: 700;
    }

    a:focus,
    a:hover {
        color: $secondary_alt;
    }
}

.swiper-button-prev,
.swiper-button-next {
    & {
        background: none;
        border: 0;
        bottom: 0;
        color: $light_alt;
        display: block;
        height: remify(125, 16);
        margin: 0;
        opacity: 1;
        padding: 0;
        top: auto;
        transition: color 0.15s;
        width: remify(38.5, 16);
    }

    &:hover,
    &:focus {
        color: darken($light_alt, 10);
    }

    .svg-icon {
        font-size: remify(38.5, 16);
        left: 50%;
        position: absolute;
        transform: translateX(-50%) translateY(-50%);
        top: 50%;
    }
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    & {
        background: none;
        left: 0;
    }
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    & {
        background: none;
        right: 0;
    }
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
    & {
        bottom: auto;
        top: 0;
    }
}

.swiper-pagination-bullet {
    & {
        background: transparentize($dark, 0.5);
        height: remify(10, 16);
        opacity: 1;
        transition: background 0.15s;
        width: remify(10, 16);
    }
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    & {
        margin: remify(20 5, 16);
    }
}

.swiper-pagination-bullet-active {
    & {
        background: $primary;
    }
}

// alt variant

.swiper-container.-alt {
    & {
        border: 0;
        box-shadow: none;
        margin: remify(0 0 20, 16);
    }

    .swiper-caption {
        background: $background; // non rgba fallback
        background: transparentize($background, 0.25);
        border: 0;
        bottom: 0;
        box-shadow: none;
        left: 0;
        padding-left: remify(60, 16);
        padding-right: remify(60, 16);
        position: absolute;
        right: 0;
    }

    .swiper-text,
    .swiper-caption .user-content figcaption,
    .swiper-caption .user-content ol,
    .swiper-caption .user-content p,
    .swiper-caption .user-content table,
    .swiper-caption .user-content ul {
        color: $primary;
        font-size: remify(15, 16);
        font-weight: 700;
        margin-bottom: 0;
    }

    .swiper-button-prev,
    .swiper-button-next {
        color: $accent;
        height: remify(75, 16);
        width: remify(60, 16);
    }

    .swiper-button-prev:hover,
    .swiper-button-prev:focus,
    .swiper-button-next:hover,
    .swiper-button-next:focus {
        color: $accent_alt;
    }

    .swiper-button-prev .svg-icon,
    .swiper-button-next .svg-icon {
        font-size: remify(32, 16);
    }
}
