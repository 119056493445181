// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Speach Bubble
\* ------------------------------------------------------------------------ */

.speach-bubble {
    & {
        background: url("../media/background-speach-bubble.svg") center center / 100% 100% no-repeat;
        color: $foreground;
        display: block;
        position: relative;
        font-family: $heading-font;
        font-size: remify(18, 16);
        font-weight: 700;
        height: remify(95, 18);
        line-height: remify(17.4, 18);
        padding: remify(22 15, 18);
        width: remify(125, 18);
    }
}

// reverse variant

.speach-bubble.-reverse {
    & {
        background-image: url("../media/background-speach-bubble_reverse.svg");
    }
}
