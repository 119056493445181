// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * User Content
\* ------------------------------------------------------------------------ */

.user-content {
    // links

    a:not(.button) {
        color: $accent;
        font-weight: 700;
        text-decoration: none;
        transition: color 0.15s;
    }

    a:not(.button):hover {
        color: $accent_alt;
    }

    // titles

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $accent;
        font-family: $heading-font;
        font-weight: 700;
    }

    h1,
    h2 {
        font-size: remify(37.6, 16);
        line-height: remify(37.6, 37.6);
        margin: remify(0 0 10, 37.6);
    }

    h2 {
        color: $primary_alt;
    }

    h3,
    h4 {
        font-family: $body-font;
        font-size: remify(19, 16);
        line-height: remify(16, 19);
        margin: remify(0 0 14, 19);
    }

    h4 {
        color: $secondary;
    }

    h5 {
        font-size: remify(16, 16);
        line-height: remify(24, 16);
        margin: remify(0 0 8, 16);
    }

    h6 {
        font-size: remify(14, 16);
        line-height: remify(21, 14);
        margin: remify(0 0 7, 14);
    }

    // text

    figcaption,
    ol,
    p,
    table,
    ul {
        color: $foreground;
        font-family: $body-font;
        font-size: remify(13.5, 16);
        font-weight: 400;
        line-height: remify(17.6, 13.5);
        margin: remify(0 0 17.6, 13.5);
    }

    figcaption {
        font-size: remify(10.5, 16);
        margin-top: remify(10.5, 10.5);
    }

    ol,
    ul {
        padding-left: remify(18, 14);
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }

    table td,
    table th {
        border: remify(1) solid darken($background_alt, 10);
        padding: remify(7.5 15, 14);
        text-align: left;
        vertical-align: top;
    }

    table tbody th,
    table > tr > th,
    table[data-stacked="true"] tbody td:before {
        font-weight: 700;
        text-transform: uppercase;
    }

    table thead td,
    table thead th {
        background: $dark_alt;
        border-color: lighten($dark_alt, 10);
        color: $light;
        font-size: remify(18, 14);
        font-weight: 400;
        padding: remify(10 15, 18);
    }

    table > tr:nth-child(even) td,
    table > tr:nth-child(even) th,
    table tbody tr:nth-child(even) td,
    table tbody tr:nth-child(even) th {
        background: $background_alt;
    }

    // stacked responsive tables
    // https://techblog.livingsocial.com/blog/2015/04/06/responsive-tables-in-pure-css/

    @media screen and (max-width: ($s-break - 1 / 16)) {
        table[data-stacked="true"] thead {
            left: -999em;
            position: absolute;
            top: -999em;
        }

        table[data-stacked="true"] tbody tr {
            display: block;
        }

        table[data-stacked="true"] tbody tr:not(:last-child) {
            margin-bottom: remify(10, 14);
        }

        table[data-stacked="true"] tbody td {
            display: flex;
        }

        table[data-stacked="true"] tbody td + td {
            border-top: 0;
        }

        table[data-stacked="true"] tbody td:before {
            content: attr(data-label);
            flex: 1 1 auto;
            text-align: left;
            width: 50%;
        }

        table[data-stacked="true"] tbody td span.-cell {
            flex: 1 1 auto;
            text-align: right;
            width: 50%;
        }
    }

    // blockquote

    blockquote {
        border: remify(1) solid darken($background_alt, 10);
        border-left: 0;
        border-right: 0;
        font-style: italic;
        margin-left: remify(40, 14);
        margin-right: remify(40, 14);
        padding: remify(21 0, 14);
    }

    blockquote blockquote,
    blockquote p,
    blockquote ol,
    blockquote ul,
    blockquote table {
        color: lighten($foreground, 50);
        font-size: remify(18, 14);
    }

    blockquote > :last-child {
        margin-bottom: 0 !important;
    }

    // horizontal rule

    hr {
        background: url("../media/border-dashed_horizontal_tertiary.png") center left repeat-x;
        border: 0;
        clear: both;
        height: remify(3);
        margin: remify(0 0 25, 16);
        width: 100%;
    }

    // images

    img {
        border: remify(5) solid $tertiary;
        display: block;
        height: auto !important;
        margin: remify(0 0 20, 16);
        max-width: 100%;
        min-height: remify(50, 16);
        position: relative;
        width: 100%;
    }

    img.-clean {
        border: 0;
    }

    img.-round {
        border-radius: 50%;
    }

    img:before {
        background: $background_alt;
        border: remify(1) dotted darken($background_alt, 10);
        border-radius: remiefy(5, 16);
        content: "\0020";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        width: 100%;
    }

    img:after {
        color: lighten($foreground, 20);
        content: "\F127\0020" attr(alt);
        display: block;
        font-family: "FontAwesome";
        font-size: remify(16, 16);
        font-style: normal;
        left: 0;
        position: absolute;
        text-align: center;
        top: remify(5, 16);
        width: 100%;
    }
}

// desktop variant

@media screen and (min-width: $s-break) {
    .user-content {
        h1,
        h2 {
            font-size: remify(47, 16);
        }

        h3,
        h4 {
            font-size: remify(24, 16);
        }

        p,
        ol,
        ul,
        table {
            font-size: remify(17, 16);
        }

        figcaption {
            font-size: remify(14.5, 16);
        }

        img {
            margin-bottom: 0;
            width: auto;
        }

        img:not(.-clean) {
            border-width: remify(10);
        }

        .alignleft,
        .align-left {
            float: left;
            margin-right: remify(25, 16);
            max-width: (100% / 3);
        }

        .alignright,
        .align-right {
            float: right;
            margin-left: remify(25, 16);
            max-width: (100% / 3);
        }

        .aligncenter,
        .align-center {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
