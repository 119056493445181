// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

.swiper-container {
    & {
        border: remify(10, 16) solid $tertiary;
        border-radius: remify(20, 16);
        box-shadow: remify(0 0 8 1, 16) transparentize($dark, 0.75);
    }
}

.swiper-caption {
    & {
        border-bottom: 0;
        border-top-width: remify(10, 16);
        padding: remify(25 60, 16);
    }
}

.swiper-button-prev,
.swiper-button-next {
    & {
        height: remify(140, 16);
        width: remify(40, 16);
    }

    .svg-icon {
        font-size: remify(50, 16);
    }
}

// alt variant

.swiper-container.-alt {
    & {
        margin-bottom: 0;
    }
    
    .swiper-text {
        font-size: remify(19, 16);
    }
}
