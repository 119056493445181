// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Title
\* ------------------------------------------------------------------------ */

.title {
    & {
        color: $accent;
        font-family: $heading-font;
        font-size: remify(37.6, 16);
        font-weight: 700;
        line-height: remify(37.6, 37.6);
        margin: remify(0 0 10, 37.6);
    }
}

// desktop variant

@media screen and (min-width: $s-break) {
    .title {
        font-size: remify(47, 16);
    }
}

// sub variant

.title.-sub {
    & {
        color: $secondary;
        font-family: $body-font;
        font-size: remify(19.2, 16);
    }
}

// sub desktop variant

@media screen and (min-width: $s-break) {
    .title.-sub {
        font-size: remify(24, 16);
    }
}
