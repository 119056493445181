// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Article
\* ------------------------------------------------------------------------ */

.article_footer {
    & {
        border-top: remify(1) solid $accent;
        padding-top: remify(20, 16);
    }
}

// excerpt variant

.article.-excerpt {
    &:not(:last-child) {
        margin-bottom: remify(20, 16);
    }

    .article_title {
        color: $dark;
        font-size: remify(13.5, 16);
    }

    .article_title > a {
        @extend .link;
        color: $dark;
    }

    .article_title > a:focus,
    .article_title > a:hover {
        color: lighten($dark, 10);
    }

    .article_content > .user-content > *:last-child {
        margin-bottom: 0 !important;
    }

    .article_footer {
        border-top: 0;
        padding-top: 0;
    }

    .article_footer > .field-content > a {
        @extend .text;
        @extend .link;
        color: $accent;
        font-weight: 700;
    }

    .article_footer > .field-content > a:focus,
    .article_footer > .field-content > a:hover {
        color: $accent_alt;
    }
}

// video variant

.article.-video {
    .article_header {
        text-align: center;
    }

    .article_title {
        font-size: remify(27.6, 16);
    }

    .user-content h1,
    .user-content h2,
    .user-content h3,
    .user-content h4,
    .user-content h5,
    .user-content h6,
    .user-content figcaption,
    .user-content ol,
    .user-content p,
    .user-content table,
    .user-content ul {
        color: $light;
    }
}
