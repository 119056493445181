// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Gridly (adapted from https://github.com/IonicaBizau/gridly)
\* ------------------------------------------------------------------------ */

.row {
    & {
        width: 100%;
    }

    &.-mobile {
        display: flex;
    }

    &.-center {
        align-items: center;
        justify-content: center;
    }

    &.-hcenter {
        justify-content: center;
    }

    &.-vcenter {
        align-items: center;
    }

    &.-left {
        justify-content: flex-start;
    }

    &.-right {
        justify-content: flex-end;
    }

    &.-top {
        align-items: flex-start;
    }

    &.-bottom {
        align-items: flex-end;
    }

    &.-stretch {
        align-items: stretch;
    }

    &.-around {
        justify-content: space-around;
    }

    &.-between {
        justify-content: space-between;
    }

    &.-column {
        flex-direction: column;
    }

    &.-reverse {
        flex-direction: row-reverse;
    }

    &.-column.-reverse {
        flex-direction: column-reverse;
    }

    &.-padded {
        width: calc(100% + #{remify(25, 16)});
    }
}

.col {
    &[data-order="1"] {
        order: 1;
    }

    &[data-order="2"] {
        order: 2;
    }

    &[data-order="3"] {
        order: 3;
    }

    &[data-order="4"] {
        order: 4;
    }

    &[data-order="5"] {
        order: 5;
    }

    &[data-order="6"] {
        order: 6;
    }

    &[data-order="7"] {
        order: 7;
    }

    &[data-order="8"] {
        order: 8;
    }

    &[data-order="9"] {
        order: 9;
    }

    &[data-order="10"] {
        order: 10;
    }

    // doesn't work yet, should in Level 4! https://drafts.csswg.org/css-values/
    &[data-order] {
        order: attr(data-order);
    }

    .row.-mobile > & {
        flex: 1 1 100%;
        min-width: 0;
    }

    .row.-mobile > &.-tenth {
        flex: 1 0 10%;
    }

    .row.-mobile > &.-fifth {
        flex: 1 0 20%;
    }

    .row.-mobile > &.-quarter {
        flex: 1 0 25%;
    }

    .row.-mobile > &.-third {
        flex: 1 0 33.3333334%;
    }

    .row.-mobile > &.-half {
        flex: 1 0 50%;
    }

    .row.-mobile > &.-none {
        flex: 0 0 auto;
    }

    .row.-padded > & {
        padding-right: remify(25, 16);
    }
}
