// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Divider
\* ------------------------------------------------------------------------ */

.divider {
    & {
        background: url("../media/background-divider.png") center center no-repeat;
        border: 0;
        clear: both;
        height: remify(12);
        margin: remify(0 0 10, 16);
        width: 100%;
    }
}
