// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    & {
        @include layer("header");
    }

    &.-fullbleed {
        background: url("../media/background-dots.png") remify(0 -150, 16) repeat-x $page_background;
    }
}

.header_inner {
    & {
        background: url("../media/background-dots.png") remify(0 -150, 16) repeat-x $page_background;
        margin: 0 auto;
        max-width: remify($site-width);
    }

    .header-block.-fullbleed > & {
        background: none;
    }
}

.header_logo {
    & {
        background: $page_background;
        padding: remify(7 17.5);
    }

    .logo_image {
        max-width: remify(200);
    }
}

.header_menu-toggle {
    & {
        color: $light;
    }
}

.header_row.-padded {
    & {
        width: calc(100% + #{remify(10, 16)});
    }

    > .col {
        padding-right: remify(10, 16);
    }
}
