// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Badge
\* ------------------------------------------------------------------------ */

.badge {
    & {
        align-items: center;
        background: $accent;
        border: remify(2.5, 27.21) solid $accent_alt;
        border-radius: 50%;
        color: $light;
        display: flex;
        font-family: $heading-font;
        font-size: remify(27.21, 16);
        font-weight: 700;
        height: remify(85, 27.21);
        justify-content: center;
        line-height: remify(26.325, 27.21);
        text-align: center;
        transform: rotate(-20deg);
        width: remify(85, 27.21);
    }
}
