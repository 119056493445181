// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Button
\* ------------------------------------------------------------------------ */

.button {
    & {
        background: url("../media/icon-chevron_right.png") center right remify(26, 16.5) / remify(18 18, 16.5) no-repeat $accent;
        border: 0;
        border-radius: remify(7.5, 16);
        color: $light;
        cursor: pointer;
        display: inline-block;
        font-family: $body-font;
        font-size: remify(16.5);
        font-weight: 700;
        line-height: remify(20, 16.5);
        outline: 0;
        margin: remify(0 0 17.5, 16.5);
        padding: remify(12 60 12 24, 16.5);
        text-decoration: none;
        transition: background 0.15s;
    }

    &:focus,
    &:hover {
        background-color: $accent_alt;
    }
}

// alt variant

.button.-alt {
    & {
        background-color: $secondary;
    }

    &:focus,
    &:hover {
        background-color: $secondary_alt;
    }
}

// alt2 variant

.button.-alt2 {
    & {
        background-color: $primary;
    }

    &:focus,
    &:hover {
        background-color: $primary_alt;
    }
}

// desktop variant

@media screen and (min-width: $s-break) {
    .button {
        font-size: remify(21);
    }
}

// dashed variant

.button.-dashed {
    & {
        background: none;
        border-image: url("../media/border-dashed_light.png") 25 fill repeat;
        border-style: solid;
        border-width: remify(25, 27.5);
        color: $primary;
        display: block;
        font-family: $heading-font;
        font-size: remify(27.5);
        padding: 0;
        position: relative;
        text-align: center;
        z-index: 1;
    }

    &:before {
        background: $tertiary;
        border-radius: remify(5, 27.5);
        bottom: remify(-6, 27.5);
        content: "\0020";
        left: remify(-6, 27.5);
        position: absolute;
        right: remify(-6, 27.5);
        top: remify(-6, 27.5);
        transition: background 0.15s;
        z-index: -1;
    }

    &:focus:before,
    &:hover:before {
        background-color: $tertiary_alt;
    }

    .button_speach-bubble {
        font-size: remify(18, 27.5);
        position: absolute;
        right: remify(-60, 18);
        top: remify(-80, 18);
        transform: rotate(7.5deg);
    }

    .button_speach-bubble.-reverse {
        bottom: remify(-85, 18);
        left: remify(-65, 18);
        right: auto;
        top: auto;
        transform: rotate(-15deg);
    }
}

// dashed desktop variant

@media screen and (min-width: $s-break) {
    .button.-dashed {
        border-width: remify(25, 46);
        font-size: remify(46);
    }
}

// small variant

.button.-small {
    & {
        font-size: remify(9.6);
    }
}

// small desktop variant

@media screen and (min-width: $s-break) {
    .button.-small {
        font-size: remify(16);
    }
}
