// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    & {
        padding: remify(40 25);
        text-align: left;
    }
}

.footer_title {
    & {
        color: $tertiary;
        font-size: remify(48, 16);
    }
}

.footer_text {
    & {
        font-size: remify(16, 16);
    }
}

.footer_logo {
    & {
        margin: 0;
        max-width: remify(215, 16);
    }
}

.footer_menu-list_container {
    .menu-list.-social {
        font-size: remify(40, 16);
    }
}
