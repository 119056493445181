// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Navigation
\* ------------------------------------------------------------------------ */

.navigation-block {
    & {
        @include layer("navigation");
    }

    &.-fullbleed {
        background: $secondary;
    }

    &.-flyout {
        background: $secondary;
        border-right: remify(1) solid darken($secondary_alt, 10);
        bottom: 999em;
        display: block;
        left: -999em;
        overflow: auto;
        padding: 0;
        position: absolute;
        right: 999em;
        top: -999em;;
        transform: translateX(-100%);
        transition: bottom 0s 0.15s, left 0s 0.15s, right 0s 0.15s, top 0s 0.15s, transform 0.15s, visibility 0s 0.15s;
        visibility: hidden;
        z-index: 999;
    }

    &.-flyout.-right {
        border-left: remify(1) solid darken($secondary_alt, 10);
        border-right: 0;
        left: 999em;
        right: -999em;
        transform: translateX(100%);
    }

    &.-flyout.is-active {
        bottom: 0;
        left: 0;
        right: remify(80);
        transition: transform 0.15s;
        transform: translateX(0);
        top: 0;
        visibility: visible;
    }

    &.-flyout.-right.is-active {
        left: remify(80);
        right: 0;
    }

    &.-pulldown {
        background: $secondary;
        border-right: remify(1) solid darken($secondary_alt, 10);
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: -999em;
        transform: translateY(-100%);
        transition: top 0s 0.15s, transform 0.15s, visibility 0s 0.15s;
        visibility: hidden;
        width: 100%;
        z-index: 999;
    }

    &.-pulldown.is-active {
        top: 0;
        transform: translateY(0);
        transition: transform 0.15s;
        visibility: visible;
    }

    &.-sticky {
        position: sticky;
        top: 0;
        width: 100% !important; // polyfill miscalculates, should always be 100%
    }

    &.-sticky.is-sticky {
        border-bottom: remify(1) solid darken($secondary_alt, 10);
    }

    @media screen and (min-width: 601px) {
        .admin-bar &.-sticky.is-sticky {
            top: 46px;
        }
    }

    @media screen and (min-width: 783px) {
        .admin-bar &.-sticky.is-sticky {
            top: 32px;
        }
    }

    &:before,
    &:after {
        background-image: url("../media/background-paper.png");
        background-repeat: no-repeat;
        content: "\0020";
        display: block;
        height: remify(9, 16);
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        width: 100%;
        z-index: 999;
    }

    &:before {
        background-position: center remify(-6, 16);
        border-top: remify(3, 16) solid $background;
        top: 0;
    }

    &.-flyout:before,
    &.-pulldown:before {
        top: remify(52, 16);
    }

    &:after {
        background-position: center top;
        border-bottom: remify(3, 16) solid $background;
        bottom: 0;
    }
}

.navigation_inner {
    & {
        background: $secondary;
        margin: 0 auto;
        max-width: remify($site-width + 30);
    }

    .navigation-block.-flyout > &,
    .navigation-block.-pulldown > & {
        background: none;
        display: flex;
        flex-direction: column;
        height: 100%;
        max-width: none;
        overflow: auto;
        padding: 0;
    }

    .navigation-block.-fullbleed > & {
        background: none;
    }
}

.navigation_menu-list_container {
    .navigation-block.-flyout &,
    .navigation-block.-pulldown & {
        flex: 1 1 auto;
    }
}
