// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Article
\* ------------------------------------------------------------------------ */

// excerpt variant

.article.-excerpt {
    .article_title {
        font-size: remify(17, 16);
    }
}

// video variant

.article.-video {
    .article_header {
        margin: remify(0 0 10, 16);
        overflow: hidden;
        position: relative;
        text-align: left;
    }

    .article_button {
        position: absolute;
        right: 0;
        top: 0;
    }

    .article_title {
        font-size: remify(46, 16);
    }

    .article_content {
        margin: 0 auto;
    }
}
